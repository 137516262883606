import React from 'react';

import expandImg from './expand.png';
import pdfImg from './pdf.png'

const ExpandIcon = (props: any) => <img src={expandImg} alt="Opens to new tab/window" 
    style={{width: 20, height: 20, opacity: 0.4}} 
    {...props}
/>

const PDFIcon = (props: any) => <img src={pdfImg} alt="Opens to new tab/window" 
    style={{width: 32, height: 32}} 
    {...props}
/>

export {
    expandImg as ExpandImg, ExpandIcon,
    pdfImg as PDFImg, PDFIcon
}