// extracted by mini-css-extract-plugin
export var artContainer = "style-module--artContainer--35524";
export var artImg = "style-module--artImg--e13ff";
export var artItem = "style-module--artItem--66884";
export var artPrevImg = "style-module--artPrevImg--e6fe7";
export var artTitle = "style-module--artTitle--65c5b";
export var articles = "style-module--articles--70248";
export var container = "style-module--container--e9edc";
export var content = "style-module--content--93728";
export var extThumbnail = "style-module--extThumbnail--953ff";
export var extTitle = "style-module--extTitle--a92ac";
export var pdfThumbnail = "style-module--pdfThumbnail--62164";
export var pdfTitle = "style-module--pdfTitle--ade67";
export var sites = "style-module--sites--a73b2";